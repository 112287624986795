main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  line-height: 1.6;
  outline: none;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #F33923 !important;
  border-color: #b21f2d !important;
}

svg {
  display: inline-block;
  vertical-align: middle;
}