.rate {
    &__description {
        font-size: 18px;
        font-weight: 600;
        line-height: 22.63px;
        margin-top: 3px;
        text-transform: capitalize;
        color: #4F4F4F;
    }

    &__total-title {
        direction: ltr;
        font-size: 20px;
        font-weight: 600;
        line-height: 25.14px;

        &--green {
            color: $color-green !important;
        }

        &--red {
            color: $color-red !important;
        }

        &--orange {
            color: #FF8B00 !important;
        }
        
        &--darkorange {
            color: #FF5630 !important;
        }
        
        &--blue {
            color: $color-blue!important;
        }

        &--darkblue {
            color: #536DFE !important;
        }

        &--rcpntSuppression {
            color: $color-red !important;
        }

        &--rcpntAcceptance {
            color: #008000 !important;
        }

        &--transmission {
            color: #665D1E !important;
        }

        &--failure {
            color: $color-red !important;
        }

        &--delay {
            color: $color-orange !important;
        }

        &--delivery {
            color: $color-green !important;
        }

        &--bounce {
            color: $color-red !important;
        }

        &--expired {
            color: #536DFE !important;
        }

        &--open {
            color: #ACE5EE !important;
        }

        &--click {
            color: #50C878 !important;
        }

        &--uniqueOpenedEmails {
            
            color: #4FBC8E!important;
            
        }
        
        &--totalRecipients {
            
            color: #2684ff!important;
            
        }

        &--acceptedRecipients {
            
            color: #36B37E!important;
            
        }
        
        &--rejectedRecipients {
            
            color: #FF7452!important;
            
        }

        &--unsubscribedRecipients {
            
            color: #FF991F!important;
            
        }
        
        &--totalEmails {
            
            color: #2684FF!important;
            
        }
        
        &--acceptedEmails {
            
            color: #36B37E!important;
            
        }
        
        &--pendingEmails {
            
            color: #FF8B00!important;
            
        }
        
        &--deliveredEmails {
            
            color: #4FBC8E!important;
            
        }

        &--uniqueOpenedEmails {
            
            color: #4FBC8E!important;
            
        }
        
        &--bouncedEmails {
            
            color: #FF7452!important;
            
        }
        
        &--delayedEmails {
            
            color: #FF9210!important;
            
        }

        &--expiredEmails {
            
            color: #0065FF!important;
            
        }

        &--uniqueLinkFollows {
            
            color: #36B37E!important;
            
        }

        &--enrolledRecipients {
            
            color: #36B37E!important;
            
        }
        
        &--campaignFailedRecipients {
            
            color: #FF7452!important;
            
        }
       
        &--sentEmails {
            
            color: #36B37E!important;
            
        }
      
        &--uniqueReadEmails {
            
            color: #4FBC8E!important;
            
        }

        &--failedEmails {
            
            color: #FF8B00!important;
            
        }
    }

    &__details {
        &__item {
            text-align: center;
            text-transform: capitalize;

            h5 {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
            }
            
            &__head {
                font-weight: 400;
               
            }

            &__body {
                font-weight: 600;
            }
        }
    }

}