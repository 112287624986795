.progress-wrap {
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .progress {
    height: fit-content;
    border-radius: 10px;
    box-shadow: none;
    background-color: #dddddd;
    margin-bottom: 0;
  }

  .progress-bar {
    background-color: $color-accent;
    color: #fff;
    box-shadow: none;
    height: 4px;

    @include directify($directions) {
      text-align: directed('right');
      #{directed('padding-right')}: 10px;
    }

    font-size: 9px;
    line-height: 13px;
  }

  p {
    margin-bottom: 5px;
  }

  .progress__label {
    color: $color-accent;
    font-size: 10px;
    bottom: 16px;
    margin: 0;
  }

  &.progress-wrap--small {
    @include directify($directions) {
      text-align: directed('left');
    }

    .progress-bar {
      height: 8px;
    }
  }

  &.progress-wrap--middle {

    .progress-bar {
      height: 12px;
    }
  }

  &.progress-wrap--big {

    .progress-bar {
      height: 16px;
    }
  }

  &.progress-wrap--rounded {

    .progress-bar {
      border-radius: 5px;
    }
  }

  &.progress-wrap--yellow {

    .progress-bar {
      background-color: $color-yellow;
    }

    .progress__label {
      color: $color-yellow;
    }
  }

  &.progress-wrap--violet {

    .progress-bar {
      background-color: $color-violet;
    }

    .progress__label {
      color: $color-violet;
    }
  }

  &.progress-wrap--pink {

    .progress-bar {
      background-color: $color-red;
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--blue {

    .progress-bar {
      background-color: $color-blue;
    }

    .progress__label {
      color: $color-blue;
    }
  }

  &.progress-wrap--darkblue {

    .progress-bar {
      background-color: #536DFE;
    }

    .progress__label {
      color: #536DFE;
    }
  }

  &.progress-wrap--red {

    .progress-bar {
      background-color: $color-red;
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--green {

    .progress-bar {
      background-color: $color-green;
    }

    .progress__label {
      color: $color-green;
    }
  }

  &.progress-wrap--orange {
    .progress-bar {
      background-color: $color-orange;
    }

    .progress__label {
      color: $color-orange;
    }
  }

  &.progress-wrap--label-top {

    .progress__label {
      position: absolute;

      @include directify($directions) {
        #{directed('right')}: 0px;
      }
    }
  }

  &.progress-wrap--pink-gradient {
    .progress-bar {
      background-color: unset;
      background-image: linear-gradient(to left, #ff7e9a, #ff4861);
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--lime-gradient {
    .progress-bar {
      background-color: unset;
      background-image: linear-gradient(to left, #d8efc1, #b8e986);
    }

    .progress__label {
      color: $color-green;
    }
  }

  &.progress-wrap--blue-gradient {
    .progress-bar {
      background-color: unset;
      background-image: linear-gradient(to left, #7edbff, #48b5ff);
    }

    .progress__label {
      color: $color-blue;
    }
  }

  &.progress-wrap--turquoise-gradient {
    .progress-bar {
      background-color: unset;
      background-image: linear-gradient(to left, #a6efda, #4ce1b6);
    }

    .progress__label {
      color: #4ce1b6;
    }
  }

  &.progress-wrap--rcpntSuppression {
    .progress-bar {
      background-color: $color-red;
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--rcpntAcceptance {
    .progress-bar {
      background-color: #008000;
    }

    .progress__label {
      color: #008000;
    }
  }

  &.progress-wrap--transmission {
    .progress-bar {
      background-color: #665D1E;
    }

    .progress__label {
      color: #665D1E;
    }
  }

  &.progress-wrap--failure {
    .progress-bar {
      background-color: $color-red;
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--delay {
    .progress-bar {
      background-color: $color-orange;
    }

    .progress__label {
      color: $color-orange;
    }
  }

  &.progress-wrap--delivery {
    .progress-bar {
      background-color: $color-green;
    }

    .progress__label {
      color: $color-green;
    }
  }

  &.progress-wrap--bounce {
    .progress-bar {
      background-color: $color-red;
    }

    .progress__label {
      color: $color-red;
    }
  }

  &.progress-wrap--expired {
    .progress-bar {
      background-color: #536DFE;
    }

    .progress__label {
      color: #536DFE;
    }
  }

  &.progress-wrap--open {
    .progress-bar {
      background-color: #ACE5EE;
    }

    .progress__label {
      color: #ACE5EE;
    }
  }

  &.progress-wrap--click {
    .progress-bar {
      background-color: #50C878;
    }

    .progress__label {
      color: #50C878;
    }
  }
    
  &.progress-wrap--uniqueOpenedEmails {
    .progress-bar {
      background-color: #4FBC8E;
    }

    .progress__label {
      color: #4FBC8E;
    }
  }
  
  &.progress-wrap--totalRecipients {
    .progress-bar {
      background-color: #2684ff;
    }

    .progress__label {
      color: #2684ff;
    }
  }

  &.progress-wrap--acceptedRecipients {
    .progress-bar {
      background-color: #36B37E;
    }

    .progress__label {
      color: #36B37E;
    }
  }
  
  &.progress-wrap--rejectedRecipients {
    .progress-bar {
      background-color: #FF7452;
    }

    .progress__label {
      color: #FF7452;
    }
  }

  &.progress-wrap--unsubscribedRecipients {
    .progress-bar {
      background-color: #FF991F;
    }

    .progress__label {
      color: #FF991F;
    }
  }
  
  &.progress-wrap--totalEmails {
    .progress-bar {
      background-color: #2684FF;
    }

    .progress__label {
      color: #2684FF;
    }
  }
 
  &.progress-wrap--acceptedEmails {
    .progress-bar {
      background-color: #36B37E;
    }

    .progress__label {
      color: #36B37E;
    }
  }
  
  &.progress-wrap--pendingEmails {
    .progress-bar {
      background-color: #FF8B00;
    }

    .progress__label {
      color: #FF8B00;
    }
  }
 
  &.progress-wrap--deliveredEmails {
    .progress-bar {
      background-color: #4FBC8E;
    }

    .progress__label {
      color: #4FBC8E;
    }
  }

  &.progress-wrap--uniqueOpenedEmails {
    .progress-bar {
      background-color: #4FBC8E;
    }

    .progress__label {
      color: #4FBC8E;
    }
  }
  
  &.progress-wrap--bouncedEmails {
    .progress-bar {
      background-color: #FF7452;
    }

    .progress__label {
      color: #FF7452;
    }
  }
  
  &.progress-wrap--delayedEmails {
    .progress-bar {
      background-color: #FF9210;
    }

    .progress__label {
      color: #FF9210;
    }
  }

  &.progress-wrap--expiredEmails {
    .progress-bar {
      background-color: #0065FF;
    }

    .progress__label {
      color: #0065FF;
    }
  }

  &.progress-wrap--uniqueLinkFollows {
    .progress-bar {
      background-color: #36B37E;
    }

    .progress__label {
      color: #36B37E;
    }
  }

  &.progress-wrap--enrolledRecipients {
    .progress-bar {
      background-color: #36B37E;
    }

    .progress__label {
      color: #36B37E;
    }
  }
  
  &.progress-wrap--campaignFailedRecipients {
    .progress-bar {
      background-color: #FF7452;
    }

    .progress__label {
      color: #FF7452;
    }
  }
  
  &.progress-wrap--sentEmails {
    .progress-bar {
      background-color: #36B37E;
    }

    .progress__label {
      color: #36B37E;
    }
  }
 
  &.progress-wrap--uniqueReadEmails {
    .progress-bar {
      background-color: #4FBC8E;
    }

    .progress__label {
      color: #4FBC8E;
    }
  }
  
  &.progress-wrap--failedEmails {
    .progress-bar {
      background-color: #FF8B00;
    }

    .progress__label {
      color: #FF8B00;
    }
  }
    
}

