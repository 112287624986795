.Toastify__toast-container--top-right {
    top: 70px;
}


.Toastify__toast--dark {
    background: #000;
    color: #fff;
}

.Toastify__toast--default {
    background: #ffffff;
    color: #000;
}

.Toastify__toast--info {
    background: #2684FF;
}

.Toastify__toast--success {
    background: #36B37E;
}

.Toastify__toast--warning {
    background: #FFC400
}

.Toastify__toast--error {
    background: #FF5630
}